import { Link } from "gatsby";
import { ReactComponent as ArrowRightIcon } from "../../images/icons/arrow-right.svg";
import { ICourse } from "../Header";
import Button from "../UI/Button";
import { LevelIcon } from "../../images/icons";


const NotebookCard: React.FC<ICourse> = ({
  title,
  description,
  slug,
  subtitle,
  level,
  i,
  shorttitle,
}) => {

  const colorFrom = (!i && i !== 0) ? '#7bd89b' : 
    (i + 1) % 7 === 0 ? '#7ad89b' :
    (i + 1) % 6 === 0 ? '#5296bf' :
    (i + 1) % 5 === 0 ? '#d479cc' :
    (i + 1) % 4 === 0 ? '#df6a9a' :
    (i + 1) % 3 === 0 ? '#52bac1' :
    (i + 1) % 2 === 0 ? '#f49f52' :
    (i + 1) % 1 === 0 ? '#8676e8' : 
    '#7bd89b';

const colorTo = (!i && i !== 0) ? '#4bb990' : 
    (i + 1) % 7 === 0 ? '#4bb990' :
    (i + 1) % 6 === 0 ? '#3d81e6' :
    (i + 1) % 5 === 0 ? '#c25bd2' :
    (i + 1) % 4 === 0 ? '#e64d69' :
    (i + 1) % 3 === 0 ? '#3f93c1' :
    (i + 1) % 2 === 0 ? '#e57e20' :
    (i + 1) % 1 === 0 ? '#5956c4' : 
    '#4bb990';

  return (
    <div
      className="w-full p-6 lg:p-8 max-w-[1140px] mx-auto flex flex-col lg:flex-row lg:gap-8 rounded-2xl transition-transform"
      style={{
        background: `linear-gradient(to right, ${colorFrom}, ${colorTo})`,
      }}
    >
      <div className="w-full">
        <Link to={`${slug}`}>
          <h4 className="text-2xl lg:text-[26px] font-semibold text-white-default mb-4">
            {shorttitle ? shorttitle : title}
          </h4>
        </Link>
        <p className="text-md text-white-default font-semibold">{subtitle}</p>
        <p className="hidden lg:block text-sm text-white-default pt-2">
          {description}
        </p>
        <div className="xl:flex items-center justify-between xl:mt-8">
          <div className="my-6 xl:my-0 xl:flex-wrap xl:flex xl:gap-2">
            <div className="flex flex-wrap gap-1.5">
              <div className="flex items-center px-2 py-2 gap-1 border border-white-default border-opacity-20 h-10 rounded-lg">
                <LevelIcon className="text-white-default" number={level} />
                <span className="text-white-default text-sm font-semibold">
                  {level === 1 && "Beginner"}
                  {level === 2 && "Intermediate"}
                  {level === 3 && "Advanced"}
                  {level === 100 && "Industry Use Case"}
                  {level === 200 && "Use Cases"}
                </span>
              </div>
            </div>
          </div>
          <Button
            className="w-full"
            size="small"
            to={`${slug}`}
            label="View Solution"
            EndIcon={ArrowRightIcon}
            type="outline"
            color="white"
          />
        </div>
      </div>
    </div>
  );
};

export default NotebookCard;
